import { Table } from '@norges-domstoler/dds-components';
import { MeddommerInnsyn } from 'src/models/meddommer';

type MeddommerInnsynTableRowProps = {
  meddommer: MeddommerInnsyn;
};

export function MeddommerInnsynTableRow({
  meddommer,
}: MeddommerInnsynTableRowProps) {
  return (
    <Table.Row>
      <Table.Cell>{meddommer.fornavn}</Table.Cell>
      <Table.Cell>{meddommer.etternavn}</Table.Cell>
      <Table.Cell>{meddommer.fødselsår}</Table.Cell>
      <Table.Cell>{meddommer.postnummer}</Table.Cell>
      <Table.Cell>{meddommer.type}</Table.Cell>
    </Table.Row>
  );
}
