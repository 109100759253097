import * as yup from 'yup';
import i18next from 'i18next';
import { capitalize } from 'src/utils/utils';

yup.setLocale({
  mixed: {
    required: () => i18next.t('yup.validation.required'),
  },
  string: {
    length: error =>
      i18next.t('yup.validation.length', {
        path: capitalize(error.path),
        length: error.length,
      }),
    email: error =>
      i18next.t('yup.validation.valid.email', { value: error.value }),
  },
});
