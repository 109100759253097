export function searchIgnoreCase(value: string, searchText: string) {
  return value.toLowerCase().includes(searchText.toLowerCase());
}

export function toLocaleIsoString(date: Date): string {
  const tzoffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - tzoffset)
    .toISOString()
    .slice(0, -1)
    .split('.')[0];
}

export function capitalize(word: string) {
  if (typeof word !== 'string' || word.length === 0) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}
