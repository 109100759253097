import { SortOrder } from '@norges-domstoler/dds-components';

function compare(
  aVal: unknown,
  bVal: unknown,
  collator: Intl.Collator
): number {
  if (typeof aVal === 'number' && typeof bVal === 'number') {
    return aVal - bVal;
  }
  if (typeof aVal === 'string' && typeof bVal === 'string') {
    return collator.compare(aVal, bVal);
  }
  if (aVal instanceof Date && bVal instanceof Date) {
    return aVal.getTime() - bVal.getTime();
  }

  return 0;
}

function getPropertyValue<T, K extends NestedKeyOf<T>>(object: T, property: K) {
  return (
    (property as string)
      .split('.')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((a, b) => a[b as keyof typeof a], object as any)
  );
}

function getValue<T>(
  aVal: T,
  bVal: T,
  key: NestedKeyOf<T>,
  sortDirection: SortOrder
): number {
  return sortDirection === 'ascending'
    ? getPropertyValue(aVal, key)
    : getPropertyValue(bVal, key);
}

export function sortBy<T>(
  array: T[],
  iteratees: NestedKeyOf<T> | NestedKeyOf<T>[],
  sortDirection: SortOrder = 'ascending'
): T[] {
  const arrayCopy = [...array];

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  if (iteratees instanceof Array) {
    return arrayCopy.sort((a, b) => {
      for (const iteratee of iteratees) {
        const aVal = getValue(a, b, iteratee, sortDirection);
        const bVal = getValue(b, a, iteratee, sortDirection);

        const result = compare(aVal, bVal, collator);
        if (result !== 0) {
          return result;
        }
      }

      return 0;
    });
  } else {
    const property = iteratees as NestedKeyOf<T>;
    return arrayCopy.sort((a, b) => {
      const aVal = getValue(a, b, property, sortDirection);
      const bVal = getValue(b, a, property, sortDirection);
      return compare(aVal, bVal, collator);
    });
  }
}
