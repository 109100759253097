import { API_PATH_PREFIX } from 'src/Routes';
import { XsrfToken } from 'src/models/xsrftoken';

export enum ApiStatus {
  Success = 'success',
  Error = 'error',
}

export type ApiErrorType = {
  status: number;
  title: string;
  message?: string;
  errors?: {
    [key: string]: string[];
  };
};

export function getApiErrorMessage(error: ApiErrorType) {
  if (error.errors) {
    return Object.values(error.errors).flat().join('. ');
  } else if (error.message) {
    return error.message;
  }
}

export class ApiError extends Error {
  status: number;
  response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.status = response.status;
    this.response = response;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

let antiXsrfTokenPromise: Promise<XsrfToken>;

const getAntiXsrfToken = (): Promise<XsrfToken> => {
  if (!antiXsrfTokenPromise) {
    antiXsrfTokenPromise = fetch(`${API_PATH_PREFIX}/antixsrftoken`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(response => {
      if (response.ok) {
        return response.json();
      }

      throw new ApiError(response);
    });
  }

  return antiXsrfTokenPromise;
};

export async function fetcher(url: string) {
  return fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  }).then(response => {
    if (response.ok) {
      return response.json();
    }

    throw new ApiError(response);
  });
}

async function handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
  const contentType = response.headers.get('content-type');
  const hasJsonResponse =
    !!contentType && contentType.includes('application/json');

  if (hasJsonResponse) {
    const data = await response.json();

    if (response.ok) {
      return {
        status: ApiStatus.Success,
        data: data as T,
      };
    } else {
      return {
        status: ApiStatus.Error,
        error: data as ApiErrorType,
      };
    }
  }

  if (response.ok) {
    return {
      status: ApiStatus.Success,
      data: response as T,
    };
  }

  return {
    status: ApiStatus.Error,
    error: {
      status: response.status,
      title: response.statusText,
    },
  };
}

const acceptJsonHeader = {
  Accept: 'application/json',
};

const contentTypeJsonHeader = {
  'Content-Type': 'application/json',
};

export type ApiSuccessResponse<T> = {
  status: ApiStatus.Success;
  data: T;
};

export type ApiErrorResponse = {
  status: ApiStatus.Error;
  error: ApiErrorType;
};

export type ApiResponse<T = unknown> = ApiSuccessResponse<T> | ApiErrorResponse;

async function tryAjax<T>(
  func: () => Promise<Response>
): Promise<ApiResponse<T>> {
  try {
    const response = await func();
    return handleResponse<T>(response);
  } catch (error: unknown) {
    return {
      status: ApiStatus.Error,
      error: {
        status: 401,
        title: 'Sesjonen har utløpt, last siden på nytt',
      },
    };
  }
}

export const getRequest = <T = unknown>(url: string) =>
  tryAjax<T>(() => {
    return fetch(url, {
      method: 'GET',
      headers: {
        ...acceptJsonHeader,
      },
    });
  });

export const putRequest = <T = unknown>(url: string, data?: unknown) => {
  return tryAjax<T>(async () => {
    const isFormData = data instanceof FormData;
    const { token } = await getAntiXsrfToken();
    return fetch(url, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Anti-Xsrf-Token': token,
        ...acceptJsonHeader,
        ...(!isFormData && { ...contentTypeJsonHeader }),
      },
      body: data ? JSON.stringify(data) : null,
    });
  });
};

export const postRequest = <T = unknown>(
  url: string,
  data?: FormData | Record<string, unknown>
) => {
  return tryAjax<T>(async () => {
    const isFormData = data instanceof FormData;
    const { token } = await getAntiXsrfToken();
    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Anti-Xsrf-Token': token,
        ...acceptJsonHeader,
        ...(!isFormData && { ...contentTypeJsonHeader }),
      },
      body: formatPayload(data),
    });
  });
};

export const deleteRequest = <T = unknown>(
  url: string,
  data?: Record<string, unknown> | number[]
) => {
  return tryAjax<T>(async () => {
    const { token } = await getAntiXsrfToken();
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Anti-Xsrf-Token': token,
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
      },
      body: formatPayload(data),
    });
  });
};

const formatPayload = (
  data?: FormData | Record<string, unknown> | string[] | number[]
) => {
  if (data instanceof FormData) {
    return data;
  }
  return data ? JSON.stringify(data) : null;
};
