import { SWRConfig } from 'swr';
import { showErrorNotification } from './Notifications';
import { ApiError, ApiErrorType, fetcher } from './api/api';
import { Storage } from './utils/storage';
import { IS_AUTHENTICATED_KEY } from './contexts/AuthContext';
import { isOnPublicPage } from './utils/isOnPublicPage';

export type SwrProps = {
  children: React.ReactNode;
};

export function Swr({ children }: SwrProps) {
  return (
    <SWRConfig
      value={{
        fetcher: fetcher,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        onError: async (error: ApiError) => {
          if (error.status === 401) {
            if (!isOnPublicPage()) {
              window.location.href = '/bff/signin';
            } else {
              Storage.removeItem(IS_AUTHENTICATED_KEY);
            }
            return new Promise(() => {});
          }

          if (error.status >= 403) {
            const data: ApiErrorType = await error.response.json();
            showErrorNotification(data?.message ?? error.message);
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
}
