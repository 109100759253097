import { Grid, GridChild, Heading } from '@norges-domstoler/dds-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Paths } from 'src/Routes';
import { useMeddommereInnsyn } from 'src/api/hooks/useMeddommereInnsyn';
import { BackLink } from 'src/components/shared/BackLink/BackLink';
import { Page } from 'src/components/shared/Page/Page';
import { useEnheter } from 'src/contexts/EnheterContext';
import { MeddommerInnsynTable } from './MeddommerInnsynTable';

export default function MeddommerInnsynPage() {
  const { t } = useTranslation();
  const { enheter } = useEnheter();
  const [searchParams] = useSearchParams();
  const enhetNr = searchParams.get('enhet');

  const { meddommere, isLoading } = useMeddommereInnsyn(enhetNr);

  const valgtEnhet = useMemo(
    () => enheter.find(x => x.nummer === enhetNr),
    [enheter, enhetNr]
  );

  return (
    <Page>
      <Grid as="div" style={{ width: '100%' }}>
        <GridChild columnsOccupied="all">
          <BackLink href={Paths.base} />
        </GridChild>

        <GridChild columnsOccupied="all">
          <Heading level={2} className="mb-2">
            {t('innsyn.heading', { enhet: valgtEnhet?.navn })}
          </Heading>
        </GridChild>

        <GridChild columnsOccupied="all">
          <MeddommerInnsynTable
            meddommere={meddommere}
            isLoadingMeddommere={isLoading}
          />
        </GridChild>
      </Grid>
    </Page>
  );
}
