import { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { LoginErrorPage } from './components/pages/LoginErrorPage';
import MeddommerInnsynPage from './components/pages/MeddommerInnsyn/MeddommerInnsynPage';
import { NotFoundPage } from './components/pages/NotFoundPage';
import { Footer } from './components/shared/Footer/Footer';
import HiddenLogoutForm from './components/shared/HiddenLogoutForm/HiddenLogoutForm';
import { PageLoader } from './components/shared/Loader/PageLoader';
import { AuthenticatedHeader } from './components/shared/Navigation/AuthenticatedHeader';
import { Header } from './components/shared/Navigation/Header';
import { AuthProvider } from './contexts/AuthContext';
import { lazyWithReload } from './utils/lazyWithReload';
import { useLoggedInStatus } from './contexts/LoggedInStatusContext';

const ProfilPage = lazyWithReload(
  () => import('./components/pages/Profil/ProfilPage')
);

const MeddommerbehovPage = lazyWithReload(
  () => import('./components/pages/Meddomerbehov/MeddommerbehovPage')
);

const RegistrerMeddommerePage = lazyWithReload(
  () => import('./components/pages/Meddomerbehov/RegistrerMeddommerePage')
);

const MeddommerbehovStatusPage = lazyWithReload(
  () => import('./components/pages/Meddomerbehov/MeddommerbehovStatusPage')
);

const FritakPage = lazyWithReload(
  () => import('./components/pages/Fritak/FritakPage')
);

const OverfoeringPage = lazyWithReload(
  () => import('./components/pages/Overfoering/OverfoeringPage')
);

const StartPage = lazyWithReload(
  () => import('./components/pages/Start/StartPage')
);

export const API_PATH_PREFIX = '/bff';

export const Paths = {
  base: '/',
  innsyn: {
    base: '/innsyn',
  },
  behov: {
    base: '/behov',
    registrer: '/behov/registrer',
    generateRegistrerPath: (enhetId: string, behovid: string) =>
      `/behov/registrer?enhet=${enhetId}&behovId=${behovid}`,
    status: '/behov/status',
    generateStatusPath: (enhetId: string, behovid: string) =>
      `/behov/status?enhet=${enhetId}&behovId=${behovid}`,
  },
  fritak: {
    base: '/fritak',
  },
  overfoering: {
    base: '/overfoering',
  },
  profil: {
    base: '/profil',
    enhetGodkjenning: '/profil/enhet',
  },
  loginError: {
    base: '/innloggingsfeil',
  },
};

export const AppRoutes = () => {
  const { isLoggedIn } = useLoggedInStatus();
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route
          element={
            <AuthProvider>
              <AuthenticatedHeader />
              <Suspense fallback={<PageLoader />}>
                <HiddenLogoutForm />
                <Outlet />
              </Suspense>
              <Footer />
            </AuthProvider>
          }
        >
          <Route path={Paths.behov.base} element={<MeddommerbehovPage />} />
          <Route path={Paths.fritak.base} element={<FritakPage />} />
          <Route path={Paths.overfoering.base} element={<OverfoeringPage />} />
          <Route path={Paths.profil.base} element={<ProfilPage />} />
          <Route
            path={Paths.behov.registrer}
            element={<RegistrerMeddommerePage />}
          />
          <Route
            path={Paths.behov.status}
            element={<MeddommerbehovStatusPage />}
          />
        </Route>

        <Route
          element={
            <>
              {isLoggedIn ? (
                <AuthProvider>
                  <AuthenticatedHeader />
                  <Suspense fallback={<PageLoader />}>
                    <HiddenLogoutForm />
                    <Outlet />
                  </Suspense>
                  <Footer />
                </AuthProvider>
              ) : (
                <>
                  <Header />
                  <Suspense fallback={<PageLoader />}>
                    <Outlet />
                  </Suspense>
                  <Footer />
                </>
              )}
            </>
          }
        >
          <Route path={Paths.base} element={<StartPage />} />
          <Route path={Paths.innsyn.base} element={<MeddommerInnsynPage />} />
          <Route path={Paths.loginError.base} element={<LoginErrorPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
