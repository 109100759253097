import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import './yupTranslations';
import App from './App';
import 'src/assets/styles/fonts.scss';
import 'src/assets/styles/margin.scss';
import 'src/assets/styles/padding.scss';
import 'src/assets/styles/flex.scss';
import 'src/assets/styles/gap.scss';
import 'src/assets/styles/misc.scss';

const root = createRoot(document.getElementById('root') ?? document.body);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
