import { useState } from 'react';
import { useDebounce } from './useDebounce';

export function useDebouncedField(initialValue: string, delay: number) {
  const [value, setValue] = useState(initialValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const debouncedValue = useDebounce(value, delay);

  return {
    value: debouncedValue,
    onChange: onChange,
  };
}
