import {
  Button,
  Grid,
  GridChild,
  Heading,
  Typography,
} from '@norges-domstoler/dds-components';

import { Page } from '../shared/Page/Page';
import { useTranslation } from 'react-i18next';

export function LoginErrorPage() {
  const { t } = useTranslation();

  return (
    <Page>
      <Grid as="div">
        <GridChild columnsOccupied="all">
          <Heading level={2}>{t('login.error.page.heading')}</Heading>

          <Typography typographyType="bodySans02" className="mb-4">
            {t('login.error.page.info')}
          </Typography>

          <form method="post" action="/bff/signout" aria-hidden="true">
            <Button
              label={t('login.error.page.button.label')}
              type="submit"
              appearance="ghost"
              purpose="secondary"
            />
          </form>
        </GridChild>
      </Grid>
    </Page>
  );
}
