import { createRef, useEffect } from 'react';
import { IS_AUTHENTICATED_KEY } from 'src/contexts/AuthContext';
import { useLoggedInStatus } from 'src/contexts/LoggedInStatusContext';
import { Storage } from 'src/utils/storage';

export default function HiddenLogoutForm() {
  const { setLogoutCallback } = useLoggedInStatus();
  const ref = createRef<HTMLFormElement>();

  useEffect(() => {
    const form = ref.current;

    setLogoutCallback(() => {
      Storage.removeItem(IS_AUTHENTICATED_KEY);
      form?.submit();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      method="post"
      action="/bff/signout"
      style={{ display: 'none' }}
      aria-hidden="true"
      ref={ref}
    />
  );
}
