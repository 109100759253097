import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useValgPerioder } from 'src/api/hooks/useValgPerioder';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import { ValgPeriode } from 'src/models/valgPeriode';

type ValgPerioderContext = {
  valgPerioder: ValgPeriode[];
  fritakValgPerioder: ValgPeriode[];
  innevaerendePeriode: ValgPeriode;
  kommendeValgPeriode: ValgPeriode;
  valgPeriode: ValgPeriode;
  fritakValgPeriode: ValgPeriode;
  setValgPeriode: (valgPeride: ValgPeriode) => void;
  setFritakValgPeriode: (valgPeride: ValgPeriode) => void;
};

export const ValgPerioderContext = createContext<ValgPerioderContext>(
  {} as ValgPerioderContext
);

export const useValgPerioderContext = () => {
  return useContext(ValgPerioderContext);
};

export const ProvideValgPerioder = ({ children }: { children: ReactNode }) => {
  const [valgPeriode, setValgPeriode] = useState<ValgPeriode>(
    {} as ValgPeriode
  );

  const [fritakValgPeriode, setFritakValgPeriode] = useState<ValgPeriode>(
    {} as ValgPeriode
  );

  const { valgPerioder, isLoadingValgPerioder } = useValgPerioder();

  const innevaerendePeriode = useMemo(
    () => valgPerioder.find(x => x.isInnevaerendePeriode) as ValgPeriode,
    [valgPerioder]
  );

  const kommendeValgPeriode = useMemo(
    () => valgPerioder.find(x => x.isKommendePeriode) as ValgPeriode,
    [valgPerioder]
  );

  const fritakValgPerioder = useMemo(
    () =>
      valgPerioder.filter(
        period => period.isInnevaerendePeriode || period.isKommendePeriode
      ),
    [valgPerioder]
  );

  useEffect(() => {
    if (kommendeValgPeriode) {
      setValgPeriode(kommendeValgPeriode);
    }

    if (innevaerendePeriode) {
      setFritakValgPeriode(innevaerendePeriode);
    }
  }, [innevaerendePeriode, kommendeValgPeriode]);

  return (
    <ValgPerioderContext.Provider
      value={{
        valgPerioder: valgPerioder,
        fritakValgPerioder: fritakValgPerioder,
        valgPeriode: valgPeriode,
        innevaerendePeriode: innevaerendePeriode,
        kommendeValgPeriode: kommendeValgPeriode,
        fritakValgPeriode: fritakValgPeriode,
        setValgPeriode: setValgPeriode,
        setFritakValgPeriode: setFritakValgPeriode,
      }}
    >
      {isLoadingValgPerioder ? <PageLoader /> : children}
    </ValgPerioderContext.Provider>
  );
};
