import { Spinner, Typography } from '@norges-domstoler/dds-components';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { Page } from '../Page/Page';
import { Colors } from '../Tokens/colors';
import { Spacing } from '../Tokens/spacing';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  row-gap: ${Spacing.SizesDdsSpacingX1};
  width: 100%;
  opacity: 100%;
  animation: ${fadeIn} 1s;
`;

export function PageLoader() {
  const { t } = useTranslation();
  return (
    <Page>
      <SpinnerContainer>
        <Spinner color={Colors.DdsColorPrimaryBase} size="80px" />
        <Typography>{t('loading.page')}</Typography>
      </SpinnerContainer>
    </Page>
  );
}
