import { API_PATH_PREFIX } from 'src/Routes';
import { ValgPeriode } from 'src/models/valgPeriode';
import useSWR from 'swr';
import { ApiError } from '../api';

export function useValgPerioder() {
  const { data, error, isLoading } = useSWR<ValgPeriode[], ApiError>(
    () => `${API_PATH_PREFIX}/public/valgperioder`
  );

  return {
    valgPerioder: data ?? [],
    isLoadingValgPerioder: isLoading,
    error,
  };
}
