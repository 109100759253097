import { SortOrder, Table } from '@norges-domstoler/dds-components';
import { useTranslation } from 'react-i18next';
import { MeddommerInnsyn } from 'src/models/meddommer';

type MeddommerInnsynTableHeaderProps = {
  sortKey?: NestedKeyOf<MeddommerInnsyn>;
  sortOrder?: SortOrder;
  sort: (key: NestedKeyOf<MeddommerInnsyn>) => void;
};

export function MeddommerInnsynTableHeader({
  sortKey,
  sortOrder,
  sort,
}: MeddommerInnsynTableHeaderProps) {
  const { t } = useTranslation();

  const getIsSorted = (property: NestedKeyOf<MeddommerInnsyn>) => {
    return property === sortKey;
  };

  const getSortOrder = (
    property: NestedKeyOf<MeddommerInnsyn>
  ): SortOrder | undefined => {
    return property === sortKey ? sortOrder : undefined;
  };

  return (
    <Table.Head>
      <Table.Row type="head">
        <Table.SortCell
          onClick={() => sort('fornavn')}
          sortOrder={getSortOrder('fornavn')}
          isSorted={getIsSorted('fornavn')}
        >
          {t('first.name')}
        </Table.SortCell>
        <Table.SortCell
          onClick={() => sort('etternavn')}
          sortOrder={getSortOrder('etternavn')}
          isSorted={getIsSorted('etternavn')}
        >
          {t('last.name')}
        </Table.SortCell>
        <Table.SortCell
          onClick={() => sort('fødselsår')}
          sortOrder={getSortOrder('fødselsår')}
          isSorted={getIsSorted('fødselsår')}
        >
          {t('birth.year')}
        </Table.SortCell>
        <Table.SortCell
          onClick={() => sort('postnummer')}
          sortOrder={getSortOrder('postnummer')}
          isSorted={getIsSorted('postnummer')}
        >
          {t('zip')}
        </Table.SortCell>

        <Table.SortCell
          onClick={() => sort('type')}
          sortOrder={getSortOrder('type')}
          isSorted={getIsSorted('type')}
        >
          {t('type')}
        </Table.SortCell>
      </Table.Row>
    </Table.Head>
  );
}
