import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'IBM Plex Sans', Trebuchet MS, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    width: 100%;
  }

  #root {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;
