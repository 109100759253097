export const Storage = {
  getItem: <T>(key: string) => {
    const value = localStorage.getItem(key);
    try {
      return value !== null ? (JSON.parse(value) as T) : null;
    } catch {
      return null;
    }
  },

  setItem: <T>(key: string, value: T) => {
    try {
      const serializedState = JSON.stringify(value);
      localStorage.setItem(key, serializedState);
    } catch (error) {
      console.log(error);
    }
  },

  removeItem: (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Failed to remove item "${key}" from local storage`);
    }
  },
};
