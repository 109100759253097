import { ReactNode, createContext, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { API_PATH_PREFIX, Paths } from 'src/Routes';
import { ApiError } from 'src/api/api';
import { PageLoader } from 'src/components/shared/Loader/PageLoader';
import { User } from 'src/models/user';
import { Storage } from 'src/utils/storage';
import useSWR, { KeyedMutator } from 'swr';
import { useLoggedInStatus } from './LoggedInStatusContext';
import { isOnPublicPage } from 'src/utils/isOnPublicPage';

export const IS_AUTHENTICATED_KEY = 'is_authenticated';

type AuthContextType = {
  user: User;
  mutateUser: KeyedMutator<User>;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { setIsLoggedIn } = useLoggedInStatus();

  const {
    data,
    error,
    isLoading: isLoadingUser,
    mutate: mutateUser,
  } = useSWR<User, ApiError>(`${API_PATH_PREFIX}/bruker`);

  const user = data ?? ({} as User);
  const isAuthenticated = !!data;
  const isUnauthorized = error?.response?.status === 401;

  useEffect(() => {
    if (isUnauthorized) {
      setIsLoggedIn(false);
    }
  }, [isUnauthorized, setIsLoggedIn]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoggedIn(true);
      Storage.setItem(IS_AUTHENTICATED_KEY, true);
    }
  }, [isAuthenticated, setIsLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        user,
        mutateUser,
      }}
    >
      {isLoadingUser && <PageLoader />}
      {isAuthenticated && !user.enhet && <Navigate to={Paths.profil.base} />}
      {isAuthenticated && children}
      {!isAuthenticated && !isLoadingUser && isOnPublicPage() && children}
      {!isAuthenticated && !isLoadingUser && !isUnauthorized && (
        <Navigate replace to={Paths.loginError.base} />
      )}
    </AuthContext.Provider>
  );
};
