import { Link, Typography } from '@norges-domstoler/dds-components';
import { useTranslation } from 'react-i18next';
import domstolLogo from 'src/assets/images/DA_NorgesDomstoler_Negativ_RGB.svg';
import styled from 'styled-components';
import { Breakpoints } from '../Tokens/breakpoints';
import { Colors } from '../Tokens/colors';
import { Spacing } from '../Tokens/spacing';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <StyledFooter>
        <FooterLogoContainer>
          <img src={`${domstolLogo}`} alt={t('logo.alt.text')} />
        </FooterLogoContainer>
        <FooterContentColumn>
          <FooterTypography typographyType="headingSans02">
            {t('footer.name.heading')}
          </FooterTypography>
          <FooterTypography>{t('footer.organization.number')}</FooterTypography>
          <FooterTypography>
            {t('footer.postal.address.line.1')}
          </FooterTypography>
          <FooterTypography>
            {t('footer.postal.address.line.2')}
          </FooterTypography>
        </FooterContentColumn>
        <FooterContentColumn>
          <FooterTypography typographyType="headingSans02">
            {t('footer.contact.heading')}
          </FooterTypography>
          <FooterTypography>{t('footer.phone')}</FooterTypography>
          <FooterLink href={`mailto:${t('footer.mail')}`}>
            {t('footer.mail')}
          </FooterLink>
          <FooterTypography>
            {t('footer.visiting.address.line.1')}
          </FooterTypography>
          <FooterTypography>
            {t('footer.visiting.address.line.2')}
          </FooterTypography>
        </FooterContentColumn>
        <FooterContentColumn>
          <FooterTypography typographyType="headingSans02">
            {t('footer.links.heading')}
          </FooterTypography>
          <FooterLink
            href="https://www.domstol.no/contentassets/1ce8669047b14ba188baa94b630bf0a3/handbok-portal-for-meddommerutvalg-2024.pdf"
            external
          >
            {t('footer.links.user.manual')}
          </FooterLink>
          <FooterLink
            href="https://www.domstol.no/no/personvernpolicy/"
            external
          >
            {t('footer.links.privacy.cookie.notice')}
          </FooterLink>
          <FooterLink
            href="https://uustatus.no/nb/erklaringer/publisert/dfaf062b-0514-4f94-b211-62ce6c1e7c76"
            external
          >
            {t('footer.links.accessibility.notice')}
          </FooterLink>
        </FooterContentColumn>
      </StyledFooter>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  margin-top: auto;
  background-color: ${Colors.DdsColorPrimaryBase};
`;

const StyledFooter = styled.footer`
  display: flex;
  flex: 1;
  padding-top: ${Spacing.SizesDdsSpacingX3};
  min-height: ${Spacing.SizesDdsSpacingX10};
  background-color: ${Colors.DdsColorPrimaryBase};
  column-gap: ${Spacing.SizesDdsSpacingX25};
  div:nth-of-type(2) {
    margin-left: auto;
  }
  @media only screen and (max-width: ${Breakpoints.DdsBreakpointLg}) {
    flex-direction: column;
    row-gap: ${Spacing.SizesDdsSpacingX1};
    div:nth-of-type(2) {
      padding-top: ${Spacing.SizesDdsSpacingX1};
      margin-left: 0;
    }
  }
  margin-left: ${Spacing.SizesDdsSpacingX1};
  margin-right: ${Spacing.SizesDdsSpacingX1};
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointSm}) {
    margin-left: ${Spacing.SizesDdsSpacingX2};
    margin-right: ${Spacing.SizesDdsSpacingX2};
  }
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointMd}) {
    margin-left: ${Spacing.SizesDdsSpacingX4};
    margin-right: ${Spacing.SizesDdsSpacingX4};
  }
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointLg}) {
    margin-left: ${Spacing.SizesDdsSpacingX6};
    margin-right: ${Spacing.SizesDdsSpacingX6};
  }
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointXl}) {
    margin-left: ${Spacing.SizesDdsSpacingX10};
    margin-right: ${Spacing.SizesDdsSpacingX10};
  }
`;

const FooterLogoContainer = styled.div`
  display: flex;
  width: 188px;
  height: 100px;
  padding-top: 6px;
`;

const FooterContentColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTypography = styled(Typography)`
  color: ${Colors.DdsColorNeutralsGray1};
`;

const FooterLink = styled(Link)`
  color: ${Colors.DdsColorNeutralsGray1};
`;
