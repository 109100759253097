import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './App.styles';
import { Notifications } from './Notifications';
import { AppRoutes } from './Routes';
import { Swr } from './Swr';
import { ProvideValgPerioder } from './contexts/ValgPerioderContext';
import { LoggedInStatusProvider } from './contexts/LoggedInStatusContext';
import { ProvideEnheter } from './contexts/EnheterContext';

function App() {
  return (
    <>
      <GlobalStyle />
      <Notifications />
      <Swr>
        <LoggedInStatusProvider>
          <ProvideEnheter>
            <ProvideValgPerioder>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </ProvideValgPerioder>
          </ProvideEnheter>
        </LoggedInStatusProvider>
      </Swr>
    </>
  );
}

export default App;
