import styled from 'styled-components';
import { Spacing } from '../Tokens/spacing';

const StyledPage = styled.main`
  display: flex;
  margin-bottom: ${Spacing.SizesDdsSpacingX6};
`;

export type PageProps = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
};
export function Page({ className, style, children }: PageProps) {
  return (
    <StyledPage className={className} style={style}>
      {children}
    </StyledPage>
  );
}
