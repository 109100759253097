import { Grid, GridChild, Heading } from '@norges-domstoler/dds-components';
import { Page } from '../shared/Page/Page';
import { useTranslation } from 'react-i18next';

export function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <Page>
      <Grid as="div">
        <GridChild columnsOccupied="all">
          <Heading level={3}>{t('not.found.heading')}</Heading>
        </GridChild>
      </Grid>
    </Page>
  );
}
