import { Paths } from 'src/Routes';
import { Divider } from '@norges-domstoler/dds-components';
import {
  HeaderContainer,
  HeaderLink,
  HeaderSection,
  HeaderTitle,
} from './Header.styles';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderContainer>
        <HeaderSection>
          <HeaderLink to={Paths.base}>
            <HeaderTitle>{t('header.page.title')}</HeaderTitle>
          </HeaderLink>
        </HeaderSection>
      </HeaderContainer>
      <Divider color="primaryLighter" />
    </>
  );
};
