import { ReactNode, createContext, useContext, useState } from 'react';
import { Storage } from 'src/utils/storage';
import { IS_AUTHENTICATED_KEY } from './AuthContext';

type LoggedInStatusContextType = {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  logout: () => void;
  setLogoutCallback: (callback: () => void) => void;
};

const LoggedInStatusContext = createContext<LoggedInStatusContextType>(
  {} as LoggedInStatusContextType
);

export const LoggedInStatusProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    Storage.getItem(IS_AUTHENTICATED_KEY) ?? false
  );
  const [logoutCallback, setLogoutCallback] = useState(() => () => {});

  const wrapInLambdaAndSetLogoutCallback = (callback: () => void) =>
    setLogoutCallback(() => callback);

  return (
    <LoggedInStatusContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        logout: logoutCallback,
        setLogoutCallback: wrapInLambdaAndSetLogoutCallback,
      }}
    >
      {children}
    </LoggedInStatusContext.Provider>
  );
};

export const useLoggedInStatus = () => {
  return useContext(LoggedInStatusContext);
};
