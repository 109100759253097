import {
  ArrowLeftIcon,
  Button,
  ButtonProps,
} from '@norges-domstoler/dds-components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type BackLinkProps = {
  href?: string;
} & ButtonProps;

export function BackLink({ href, children, ...rest }: BackLinkProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (href) {
      navigate(href);
    }
  }, [href, navigate]);

  const label = children ?? t('back');

  return (
    <Button
      purpose="secondary"
      appearance="borderless"
      icon={ArrowLeftIcon}
      onClick={rest.onClick ?? onClick}
      className="pl-0"
      htmlProps={{
        role: 'link',
      }}
      {...rest}
    >
      {label}
    </Button>
  );
}
