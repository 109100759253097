import { ComponentType, lazy } from 'react';
import { Storage } from 'src/utils/storage';

const pageRefreshKey = 'page-has-been-force-refreshed';

export function lazyWithReload<T extends ComponentType<unknown>>(
  lazyComponent: () => Promise<{ default: T }>
) {
  return lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed =
      Storage.getItem<boolean>(pageRefreshKey);

    try {
      const component = await lazyComponent();
      Storage.setItem(pageRefreshKey, false);
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        Storage.setItem(pageRefreshKey, true);
        window.location.reload();
        return new Promise(() => {});
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
}
