import {
  Button,
  CheckCircledIcon,
  CloseIcon,
  Icon,
  WarningIcon,
} from '@norges-domstoler/dds-components';
import toast, { Toaster } from 'react-hot-toast';
import { Colors } from './components/shared/Tokens/colors';

export function showInfoNotification(message: string) {
  toast(t => (
    <ToastContent message={message} onClose={() => closeToast(t.id)} />
  ));
}

export function showSuccessNotification(message: string) {
  toast.success(t => (
    <ToastContent message={message} onClose={() => closeToast(t.id)} />
  ));
}

export function showErrorNotification(message: string) {
  toast.error(t => {
    return <ToastContent message={message} onClose={() => closeToast(t.id)} />;
  });
}

export function closeToast(toastId: string) {
  toast.dismiss(toastId);
}

export function Notifications() {
  return (
    <Toaster
      position="bottom-right"
      toastOptions={{
        duration: 10000,
        style: {
          padding: 12,
          borderRadius: 12,
          color: Colors.DdsColorNeutralsGray8,
          maxWidth: '70ch',
          background: Colors.DdsColorInfoLightest,
        },
        success: {
          icon: (
            <Icon
              icon={CheckCircledIcon}
              htmlProps={{
                style: {
                  color: Colors.DdsColorSuccessDark,
                  alignSelf: 'start',
                  marginTop: 6,
                },
              }}
            />
          ),
          style: {
            background: Colors.DdsColorSuccessLightest,
          },
          iconTheme: {
            primary: Colors.DdsColorSuccessDark,
            secondary: Colors.DdsColorNeutralsWhite,
          },
        },
        error: {
          icon: (
            <Icon
              icon={WarningIcon}
              htmlProps={{
                style: {
                  color: Colors.DdsColorDangerDark,
                  alignSelf: 'start',
                  marginTop: 6,
                },
              }}
            />
          ),
          style: {
            background: Colors.DdsColorDangerLightest,
            alignSelf: 'start',
          },
        },
      }}
    />
  );
}

type ToastContentProps = {
  message: string;
  onClose?: () => void;
};

function ToastContent({ message, onClose }: ToastContentProps) {
  return (
    <div className="flex align-center gap-4 jc-sb">
      {message}

      {onClose !== undefined && (
        <Button
          icon={CloseIcon}
          purpose="secondary"
          appearance="rounded"
          size="tiny"
          onClick={onClose}
        />
      )}
    </div>
  );
}
