import { API_PATH_PREFIX } from 'src/Routes';
import { MeddommerInnsyn } from 'src/models/meddommer';
import useSWR from 'swr';
import { ApiError } from '../api';

export function useMeddommereInnsyn(enhetNr: string | null) {
  const { data, error, isLoading } = useSWR<MeddommerInnsyn[], ApiError>(() =>
    enhetNr ? `${API_PATH_PREFIX}/public/enhet/${enhetNr}/meddommere` : null
  );

  return {
    meddommere: data ?? [],
    error,
    isLoading,
  };
}
