import { SortOrder } from '@norges-domstoler/dds-components';
import { useMemo, useState } from 'react';
import { sortBy } from 'src/utils/sort';

type SortConfig<T> = {
  key: NestedKeyOf<T>;
  order: SortOrder;
};

export function useSort<T>(
  items: T[],
  defaultConfig: Nullable<SortConfig<T>> = null
) {
  const [sortConfig, setSortConfig] =
    useState<Nullable<SortConfig<T>>>(defaultConfig);

  const sortedItems = useMemo(() => {
    if (sortConfig) {
      return sortBy(items, sortConfig.key, sortConfig.order);
    }

    return items;
  }, [items, sortConfig]);

  function sort(key: NestedKeyOf<T>) {
    let order: SortOrder = 'ascending';

    if (sortConfig?.key === key && sortConfig?.order === 'ascending') {
      order = 'descending';
    }

    setSortConfig({ key, order });
  }

  return { sortedItems, sortConfig, sort };
}
