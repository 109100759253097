import {
  Button,
  ChevronDownIcon,
  Divider,
  Icon,
  OverflowMenu,
  OverflowMenuGroup,
  PersonIcon,
  Typography,
} from '@norges-domstoler/dds-components';
import { useTranslation } from 'react-i18next';
import { Paths } from 'src/Routes';
import { useAuth } from 'src/contexts/AuthContext';
import styled from 'styled-components';
import {
  HeaderContainer,
  HeaderLink,
  HeaderNavLink,
  HeaderSection,
  HeaderTitle,
} from './Header.styles';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useLoggedInStatus } from 'src/contexts/LoggedInStatusContext';

export const AuthenticatedHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderContainer>
        <HeaderSection>
          <HeaderLink to={Paths.base}>
            <HeaderTitle>{t('header.page.title')}</HeaderTitle>
          </HeaderLink>
          <HeaderNavLink to={Paths.behov.base}>
            {t('header.links.report')}
          </HeaderNavLink>
          <HeaderNavLink to={Paths.fritak.base}>
            {t('header.links.fritak')}
          </HeaderNavLink>
          <HeaderNavLink to={Paths.overfoering.base}>
            {t('header.links.overfoering')}
          </HeaderNavLink>
          <StyledProfileLink />
        </HeaderSection>
      </HeaderContainer>
      <Divider color="primaryLighter" />
    </>
  );
};

type ProfileLinkProps = {
  className?: string;
};

const ProfileLink = ({ className }: ProfileLinkProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { logout } = useLoggedInStatus();
  const navigate = useNavigate();

  const goToProfile = useCallback(() => {
    navigate(Paths.profil.base);
  }, [navigate]);

  const menuActions = [
    {
      type: 'botton',
      onClick: goToProfile,
      title: t('profil.heading'),
    },
    {
      type: 'button',
      onClick: logout,
      title: t('logout.button.label'),
    },
  ];

  return user ? (
    <div className={className}>
      <HeaderLink
        to={Paths.profil.base}
        className="flex align-center gap-2"
        id="nav-link"
        data-testid="header-name-link"
      >
        <Icon icon={PersonIcon} />
        <div className="flex flexcol">
          {`${user.fornavn} ${user.etternavn}`}
          <Typography as="span" typographyType="supportingStyleTiny02">
            {user.enhet
              ? `${user.enhet.navn} (${user.enhet.nummer})`
              : t('enhet.empty')}
          </Typography>
        </div>
      </HeaderLink>

      <OverflowMenuGroup>
        <Button
          purpose="secondary"
          appearance="borderless"
          size="small"
          icon={ChevronDownIcon}
          aria-label={t('aria.user.menu')}
        />
        <OverflowMenu items={menuActions} data-testid="user-context-menu" />
      </OverflowMenuGroup>
    </div>
  ) : (
    <></>
  );
};

const StyledProfileLink = styled(ProfileLink)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
