import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import nbNO from './translations/nb-no.json';

const resources = {
  'nb-NO': {
    translation: nbNO,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'nb-NO',
  interpolation: {
    escapeValue: false, // react already safe from xss
  },
});

export default i18n;
