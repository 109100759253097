import { ReactNode, createContext, useContext, useMemo } from 'react';
import { API_PATH_PREFIX } from 'src/Routes';
import { ApiError } from 'src/api/api';
import { Enhet } from 'src/models/enhet';
import useSWR from 'swr';

type EnheterContext = {
  enheter: Enhet[];
  fylker: Enhet[];
  fylkerMap: Record<string, Enhet>;
  isLoadingEnheter: boolean;
};

export const EnheterContext = createContext<EnheterContext>(
  {} as EnheterContext
);

export const useEnheter = () => {
  return useContext(EnheterContext);
};

export const ProvideEnheter = ({ children }: { children: ReactNode }) => {
  const { data, isLoading } = useSWR<Enhet[], ApiError>(
    `${API_PATH_PREFIX}/public/enheter`
  );

  const enheter = useMemo(() => data ?? [], [data]);
  const fylker = useMemo(
    () => enheter.filter(x => x.type === 'Fylke'),
    [enheter]
  );

  const fylkerMap = useMemo(
    () =>
      fylker.reduce(
        (acc, enhet) => {
          acc[enhet.nummer] = enhet;
          return acc;
        },
        {} as Record<string, Enhet>
      ),
    [fylker]
  );

  return (
    <EnheterContext.Provider
      value={{
        enheter: enheter,
        fylker: fylker,
        fylkerMap: fylkerMap,
        isLoadingEnheter: isLoading,
      }}
    >
      {children}
    </EnheterContext.Provider>
  );
};
