import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Breakpoints } from '../Tokens/breakpoints';
import { Colors } from '../Tokens/colors';
import { Spacing } from '../Tokens/spacing';

export const HeaderContainer = styled.header`
  display: flex;
  min-height: 52px;
  color: ${Colors.DdsColorNeutralsGray9};
  margin-left: ${Spacing.SizesDdsSpacingX1};
  margin-right: ${Spacing.SizesDdsSpacingX1};
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointSm}) {
    margin-left: ${Spacing.SizesDdsSpacingX2};
    margin-right: ${Spacing.SizesDdsSpacingX2};
  }
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointMd}) {
    margin-left: ${Spacing.SizesDdsSpacingX4};
    margin-right: ${Spacing.SizesDdsSpacingX4};
  }
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointLg}) {
    margin-left: ${Spacing.SizesDdsSpacingX6};
    margin-right: ${Spacing.SizesDdsSpacingX6};
  }
  @media only screen and (min-width: ${Breakpoints.DdsBreakpointXl}) {
    margin-left: ${Spacing.SizesDdsSpacingX10};
    margin-right: ${Spacing.SizesDdsSpacingX10};
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  column-gap: ${Spacing.SizesDdsSpacingX2};
  align-items: center;
  width: 100%;
`;

export const HeaderTitle = styled.div`
  font-weight: 400;
  margin-top: -4px;
  font-size: 1.25rem;
  white-space: nowrap;
`;

export const HeaderLink = styled(NavLink)`
  text-decoration: none;
  color: ${Colors.DdsColorNeutralsGray9};
`;

export const HeaderNavLink = styled(HeaderLink)`
  padding: ${Spacing.SizesDdsSpacingX1};
  &.active {
    color: ${Colors.DdsColorInteractiveDarkest};
    background-color: ${Colors.DdsColorPrimaryLightest};
  }
`;
