import { useState } from 'react';

export function usePagination<T>(items: T[], pageSize: number) {
  const [visiblePage, setVisiblePage] = useState<number>(1);

  const lastIndex = visiblePage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const visibleItems = items.slice(firstIndex, lastIndex);

  return { visibleItems, pageSize, onPageChange: setVisiblePage };
}
