import {
  Pagination,
  ScreenSize,
  Search,
  Spinner,
  Table,
  useScreenSize,
} from '@norges-domstoler/dds-components';
import { Colors } from 'src/components/shared/Tokens/colors';
import { useSort } from 'src/hooks/useSort';
import { MeddommerInnsyn } from 'src/models/meddommer';
import { MeddommerInnsynTableHeader } from './MeddommerInnsynTableHeader';
import { MeddommerInnsynTableRow } from './MeddommerInnsynTableRow';
import { usePagination } from 'src/hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { useDebouncedField } from 'src/hooks/useDebouncedField';
import { useEffect, useMemo } from 'react';
import { searchIgnoreCase } from 'src/utils/utils';

type MeddommerInnsynTableProps = {
  meddommere: MeddommerInnsyn[];
  isLoadingMeddommere: boolean;
};

export function MeddommerInnsynTable({
  meddommere,
  isLoadingMeddommere,
}: MeddommerInnsynTableProps) {
  const { t } = useTranslation();

  const { value: searchText, onChange } = useDebouncedField('', 300);

  const {
    sortedItems: sortedMeddommere,
    sortConfig,
    sort,
  } = useSort<MeddommerInnsyn>(meddommere, {
    key: 'etternavn',
    order: 'ascending',
  });

  const filteredMeddommere = useMemo(
    () => sortedMeddommere.filter(x => searchIgnoreCase(x.navn, searchText)),
    [sortedMeddommere, searchText]
  );

  const {
    visibleItems: visibleMeddommere,
    pageSize,
    onPageChange,
  } = usePagination(filteredMeddommere, 15);

  useEffect(() => {
    onPageChange(1);
  }, [filteredMeddommere, onPageChange]);

  const screenSize = useScreenSize();
  const isExtraSmallScreen = screenSize === ScreenSize.XSmall;

  return (
    <Table.Wrapper>
      <Search
        defaultValue={searchText}
        onChange={onChange}
        placeholder={t('innsyn.meddommere.search')}
        componentSize="small"
        className="mb-4"
        style={{ width: 300 }}
      />

      <Table density="extraCompact" style={{ width: '100%' }}>
        <MeddommerInnsynTableHeader
          sortKey={sortConfig?.key}
          sortOrder={sortConfig?.order}
          sort={sort}
        />
        <Table.Body>
          {isLoadingMeddommere && (
            <Table.Row>
              <Table.Cell colSpan={100}>
                <div className="flex jc-center" style={{ width: '100%' }}>
                  <Spinner color={Colors.DdsColorPrimaryBase} size="40px" />
                </div>
              </Table.Cell>
            </Table.Row>
          )}

          {!isLoadingMeddommere && meddommere.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={100}>
                {t('innsyn.meddommere.empty')}
              </Table.Cell>
            </Table.Row>
          )}

          {visibleMeddommere.map(meddommer => {
            return (
              <MeddommerInnsynTableRow
                key={meddommer.id}
                meddommer={meddommer}
              />
            );
          })}
        </Table.Body>
      </Table>

      <Pagination
        itemsAmount={meddommere.length}
        withPagination={meddommere.length > pageSize}
        defaultActivePage={1}
        defaultItemsPerPage={pageSize}
        onChange={(_, page: number) => onPageChange(page)}
        smallScreen={isExtraSmallScreen}
        className="flex jc-center mt-4"
      />
    </Table.Wrapper>
  );
}
